import React, {useState, useRef, useEffect} from 'react';
import { times } from 'lodash';

import mapboxgl, { MapboxGeoJSONFeature } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { Sector, MapResult, ExplorePoint } from "@/interfaces";
import { ExploreOverlay } from '@/components/molecules';
import { baseSectorLayer } from "./ExploreLayers";
import { sources } from './ExploreSources';
import {
  addCursorLogic,
  addHoverLogic,
  simulateClearClick,
  clearSelectedId, setSelectedId
} from "@/utils/map";
import baseSectors from '@/data/scores/base_sectors.json'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_MAP_TOKEN ? process.env.REACT_APP_MAPBOX_MAP_TOKEN : '';
const MAP_STYLE = 'mapbox://styles/mpilarczykstarcount/cl3c3adtf001214ndmp4jcmn8';

interface ExploreMapProps {
  pointFeatures: Array<ExplorePoint>;
  updateFeaturesSelected: (value: string) => void;
  updateFeatureColor: (value: string, color: string) => void;
}

export const ExploreMapGL: React.FC<ExploreMapProps> = ({
  pointFeatures, updateFeaturesSelected, updateFeatureColor
}) => {

  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [activePolygons, setActivePolygons] = useState<Array<string|number>>([]);
  const [activePoint, setActivePoint] = useState<string|number|null>(null);

  let hovered: string | number | undefined = undefined;
  let selectedPolygons: Array<string | number> = [];
  let selectedPoint: string | number | undefined = undefined;

  const sectorPopup = new mapboxgl.Popup({closeButton: false, closeOnMove: true, closeOnClick: true});

  // Load the Map
  useEffect(() => {
    if (map.current) return; // initialize map only once
    if (mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: MAP_STYLE,
        center: [-2.5, 53.14],
        zoom: 5.77,
        pitch: 0,
        touchZoomRotate: false,
        dragRotate: false,
        attributionControl: false,
        maxBounds: [[-30, 36], [30, 66]]
      });
    }
  });

  // Add Map Sources, Layers and Interactivity
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize

    map.current.on('load', () => {

      // Add points
      map.current!.addSource('points', {type: 'geojson', data: '/data/explore_points.geojson', generateId: true});
      pointFeatures.forEach((point) => {
        const label = `${point.type.toLowerCase().replace(/ /g, '_')}_points`;
        map.current!.addLayer({
          id: label,
          type: 'circle',
          source: 'points',
          paint: {
            'circle-color': [
              'case',
              ['boolean', ['feature-state', 'selected'], false],
              '#EDBE32',
              '#eb6f09',
            ],
            'circle-radius': [
              'case',
              ['boolean', ['feature-state', 'selected'], false],
              10,
              point.class === 'Crown Estate Assets' ? 8 : 5,
            ],
            'circle-stroke-width': 1,
            'circle-stroke-color': '#efefef',
            'circle-opacity': [
              'case',
              ['boolean', ['feature-state', 'selected'], false],
              1,
              point.class === 'Crown Estate Assets' ? 1 : 0.7,
            ],
          },
          filter: ['==', 'type', point.type]
        });
        if (point.class !== 'Crown Estate Assets') {
          map.current!.setLayoutProperty(label, 'visibility', 'none'); // Hide
        }
        map.current!.on('mouseenter', label, () => {
          map.current!.getCanvas().style.cursor = 'pointer';
        });
        map.current!.on('mouseleave', label, () => {
          map.current!.getCanvas().style.cursor = '';
        });
        map.current!.on('click', label, (event) => {

          event.preventDefault();
          // Set points as the highest layer
          event.originalEvent.cancelBubble = true;

          // Clear previous selection
          if (selectedPoint !== undefined) {
            clearSelectedId(map.current!, selectedPoint, 'points', undefined);
          }
          if (selectedPolygons.length > 0) {
            selectedPolygons.forEach(id => clearSelectedId(map.current!, id, 'postal-sector-source', 'Sector_TCE'));
            selectedPolygons = [];
          }

          // Set selected values
          if (event.features && event.features.length > 0) {
            const feature: MapboxGeoJSONFeature = event.features[0];
            selectedPoint = feature.id;
            selectedPoint !== undefined && setSelectedId(map.current!, selectedPoint, 'points', undefined);
            setActivePolygons([]);
            feature.properties && setActivePoint(feature.properties.feature_id);

            // Add popup
            // @ts-ignore
            const coordinates = feature.geometry.coordinates.slice();
            const contents = feature.properties && feature.properties.name;
            contents && new mapboxgl.Popup({closeButton: false}).setLngLat(coordinates).setHTML(contents).addTo(map.current!);
          }
        });
      });

      // Add sources and layers
      sources.forEach(({name, url}) => {
        map.current!.addSource(name, { type: 'vector', url });
      });
      map.current!.addLayer({...baseSectorLayer}, 'waterway-label');

      // Add logic
      addCursorLogic(map.current!, 'base-sector');
      addHoverLogic(
        map.current!,
        'base-sector',
        'postal-sector-source',
        'Sector_TCE',
        hovered,
        sectorPopup,
      );
      // addClickLogic(
      //   map.current!,
      //   'base-sector',
      //   'postal-sector-source',
      //   'Sector_TCE',
      //   selectedPolygons,
      //   selectedPoint,
      //   setActivePolygons,
      //   loading
      // );

      // Define logic for polygon click
      map.current!.on('click', 'base-sector', (event) => {

        event.preventDefault();
        if (event.originalEvent.cancelBubble) {
          return;
        }

        if (event.lngLat.lat === 0) {
          selectedPolygons.forEach(id => clearSelectedId(map.current!, id, 'postal-sector-source', 'Sector_TCE'));
          selectedPolygons = [];
          setActivePolygons([]);
        } else {
          if (event.features && event.features.length > 0) {
            const feature = event.features[0];

            if (feature.id) {

              if (selectedPoint !== undefined) {
                clearSelectedId(map.current!, selectedPoint, 'points', undefined);
                selectedPoint = undefined;
              }
              setActivePoint(null);

              if (event.originalEvent.ctrlKey) {
                if (selectedPolygons.includes(feature.id)) {
                  clearSelectedId(map.current!, feature.id, 'postal-sector-source', 'Sector_TCE');
                  selectedPolygons = selectedPolygons.filter(x => x !== feature.id);
                  setActivePolygons(selectedPolygons);
                } else {
                  selectedPolygons = [...selectedPolygons, feature.id];
                  setActivePolygons(selectedPolygons);
                  setSelectedId(map.current!, feature.id, 'postal-sector-source', 'Sector_TCE');
                }
              } else {
                selectedPolygons.forEach(id => clearSelectedId(map.current!, id, 'postal-sector-source', 'Sector_TCE'));
                selectedPolygons = [feature.id];
                setActivePolygons(selectedPolygons);
                setSelectedId(map.current!, feature.id, 'postal-sector-source', 'Sector_TCE');
              }
            }
          }
        }
      });

      // Define logic for non point/polygon click
      map.current!.on('click', (event) => {
        if (!event.defaultPrevented) {
          // Clear from map
          if (selectedPoint !== undefined) {
            clearSelectedId(map.current!, selectedPoint, 'points', undefined);
          }
          if (selectedPolygons.length > 0) {
            selectedPolygons.forEach(id => clearSelectedId(map.current!, id, 'postal-sector-source', 'Sector_TCE'));
          }
          // Clear variables
          selectedPoint = undefined;
          selectedPolygons = [];
          // onClearFact(); // TODO
          setActivePolygons([]);
          setActivePoint(null);
        }
      });

      // Add base map
      setBaseMap();

      // Add attribution
      const att = new mapboxgl.AttributionControl({customAttribution: ' Contains public sector information licensed under the Open Government Licence v3.0.'});
      map.current && map.current.addControl(att, 'bottom-right');

      // Add zoom button
      const nav = new mapboxgl.NavigationControl({
        showCompass: false,
        visualizePitch: false
      });
      map.current && map.current.addControl(nav, 'bottom-right');

      setMapLoaded(true);
    });
  });

  // Display points
  useEffect(() => {
    if (!mapLoaded) return;
    pointFeatures.forEach((point) => {
      const label = `${point.type.toLowerCase().replace(/ /g, '_')}_points`
      map.current!.setLayoutProperty(
        label,
        'visibility',
        point.selected ? 'visible' : 'none'
      )
      if ('color' in point) {
        const colorRule = ['case',['boolean', ['feature-state', 'selected'], false], '#EDBE32', point.color];
        map.current!.setPaintProperty(label, 'circle-color', colorRule);
      } else {
        const colorRule = ['case',['boolean', ['feature-state', 'selected'], false], '#EDBE32','#eb6f09'];
        map.current!.setPaintProperty(label, 'circle-color', colorRule);
      }
    });
  });

  const clearMap = () => {
    if (!map.current) return;

    setActivePolygons([]);
    simulateClearClick(map.current);

    [...times(9576)].forEach((x) => {
      map.current && map.current.setFeatureState(
        { id: x + 1, source: 'postal-sector-source', sourceLayer: 'Sector_TCE' },
        { score: null, name: null }
      )
    })
  };

  const setBaseMap = () => {
    if (!map.current) return;

    setActivePolygons([]);
    setActivePoint('');
    selectedPoint = undefined;
    selectedPolygons = [];
    updateFeaturesSelected('none');
    simulateClearClick(map.current);

    baseSectors.forEach((x: Sector) => {
      map.current && map.current.setFeatureState(
        { id: x.gid, source: 'postal-sector-source', sourceLayer: 'Sector_TCE' },
        { score: x.score, name: x.sector }
      )
    })
  };

  const updateMap = (data: MapResult) => {

    if (!map.current) return;
    clearMap();
    data.sectors.forEach((sector) => {
      map.current && map.current.setFeatureState(
        { id: sector.gid, source: 'postal-sector-source', sourceLayer: 'Sector_TCE' },
        { score: sector.score, name: sector.sector }
      )
    });
  };

  return (
    <div className='map-container'>
      <div ref={mapContainer} className='map'/>
      <ExploreOverlay
        selected={activePolygons}
        point={activePoint}
        pointFeatures={pointFeatures}
        setPointSelected={updateFeaturesSelected}
        setPointColor={updateFeatureColor}
        updateMap={updateMap}
        clearMap={setBaseMap}
        loading={loading}
        setLoading={setLoading}/>
    </div>
  );
}
