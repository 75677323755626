import React  from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function abbreviateNumber(value: number){

  const SI_SYMBOL = ['', 'k', 'M', 'B', 'T', 'P', 'E'];

  // what tier? (determines SI symbol)
  const tier = Math.log10(Math.abs(value)) / 3 | 0;

  // if zero, we don't need a suffix
  if(tier === 0) return value;

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = value / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
}


type Conversion = {
  [key: string]: string
}

type Scores = {
  [key: string]: string | number;
}

type FactValueProps = {
  scores: Scores;
  source: string;
  conversions?: Conversion;
  orderKeys?: Array<string>;
};

export const FactPointValue: React.FC<FactValueProps> = ({
  scores, source, conversions={}, orderKeys=null
}) => {

  const keys = orderKeys ? orderKeys : Object.keys(scores);

  function convertVal(val: number, type: string, source: string) {
    const conv = conversions[source];
    if (conv) {
      if (conv === 'currency') {
        return `£${abbreviateNumber(val)}`;
      } else if (conv === 'rate') {
        return `${Math.round(val * 100)}%`;
      } else if (conv === 'decimal') {
        return val.toFixed(2);
      } else {
        return abbreviateNumber(Math.round(val));
      }
    } else {
      if (type.includes('Rate') || type.includes('Percentage') || type.includes('Growth') || type.includes('Age Band') || type.includes('Income Band') || type.includes('Lifestage')) {
        return `${Math.round(val * 100)}%`;
      } else if (/[a-zA-Z ]/.test(val.toString())) {
        return val.toString();
      } else {
        if (val > 100) {
          return abbreviateNumber(Number(val % 1 === 0 ? Math.round(val) : val.toFixed(0)));
        } else {
          return abbreviateNumber(Number(val % 1 === 0 ? Math.round(val) : val.toFixed(2)));
        }
      }
    }
  }

  return (
    // <Grid item xs={12} container spacing={1}>
      <Table size='small' padding='none'>
        <TableHead>
          <TableRow>
            <TableCell sx={{fontWeight: 600, fontSize: '12px', pb: 1, width: '40%'}}>Name</TableCell>
            <TableCell align='right' sx={{fontWeight: 600, fontSize: '12px', pb: 1, width: '10%'}}>UK Base</TableCell>
            <TableCell align='right' sx={{fontWeight: 600, fontSize: '12px', pb: 1, width: '20%'}}>Value</TableCell>
          </TableRow>
        </TableHead>
      {keys.map((type, idx) => {
        const row = scores[type];
        const key = type.replace(/^[0-9]+-? /m, '');
        const parts = (typeof row === 'string' && row.split('|').length > 1) ? row.split('|') : [row, null]
        const val = Number(parts[0]) ? convertVal(Number(parts[0]), key, source) : parts[0];
        const base = parts[1] ? convertVal(Number(parts[1]), key, source) : null;
        const valColor = parts[1] ? Number(parts[0]) >= Number(parts[1]) ? '#4ED469' : '#F3546D' : null;
        if (parts[0]) {
          return (
              <TableRow>
                <TableCell align='left' sx={{fontSize: '12px', py: 1}}>
                  {key}
                </TableCell>
                <TableCell align='right' sx={{fontSize: '12px', py: 1}}>
                  {base}
                </TableCell>
                <TableCell align='right' sx={{fontSize: '12px', py: 1, color: valColor}}>
                  {val}
                </TableCell>
              </TableRow>

            // <Grid key={`value-grid-${source}-${idx}`} item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            //   <Typography sx={{ fontSize: '12px' }}>{key}</Typography>
            //   <Box sx={{ display: 'flex', flexDirection: 'row'}}>
            //     {base ?
            //       <Typography sx={{ fontSize: '12px', mr: 2 }} style={{ color: '#a6afc7' }}>{base}</Typography>
            //       : null
            //     }
            //     <Typography sx={{fontSize: '12px', minWidth: '36px', textAlign: 'right' }} style={{color: valColor ? valColor : '#FFF'}}>{val}</Typography>
            //   </Box>
            // </Grid>
          )
        } else {
          return null;
        }
      })}
      </Table>
    // </Grid>
  );
};
