import React, {useState, useEffect} from 'react';
import Draggable from 'react-draggable';

import Dialog  from '@mui/material/Dialog';
import DialogTitle  from '@mui/material/DialogTitle';
import DialogContent  from '@mui/material/DialogContent';
import DialogActions  from '@mui/material/DialogActions';
import Box  from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PaletteIcon from "@mui/icons-material/Palette";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";

import { ExplorePoint } from "@/interfaces";
import { Tooltip } from '@/components/atoms';


const COLOURS = ['#26ACA5','#4ED469','#97D54F','#F7A553','#F3546D','#F253D3','#9D53F2','#537CF2','#3290EE'];
const DEFAULT_COLOUR = '#eb6f09';

function getNextColor(x: ExplorePoint) {
  const index = 'color' in x ? COLOURS.indexOf(x.color) : 3;
  return COLOURS[(index + 1) % COLOURS.length];
}

function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#explore-point-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type ExplorePointDialogProps = {
  open: boolean;
  points: Array<ExplorePoint>;
  setPointSelected: (value: string) => void;
  setPointColor: (value: string, color: string) => void;
  onClose: () => void;
  // onApply: (factor: ExploreFactor, buckets: Array<string>) => void;
};


export const ExplorePointDialogNew: React.FC<ExplorePointDialogProps> = (
  {
    open,
    points,
    setPointSelected,
    setPointColor,
    onClose,
  }) => {

  const [selection, setSelection] = useState<Array<string>>(points.filter(x => x.selected).map(x => x.type));
  const [expanded, setExpanded] = useState<string>('');

  const groups = Array.from(new Set(points.map(val => val.class)));
  const numSelectedPoints = points.filter(x => x.selected).length;

  useEffect(() => {
    setSelection(points.filter(x => x.selected).map(x => x.type));
  }, [points, numSelectedPoints]);

  const onExpand = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  const onSelect = (value: string) => {
    const update = [...selection];
    update.includes(value)
      ? setSelection(update.filter(x => x !== value))
      : setSelection([...update, value])
    setPointSelected(value);
  }

  const onClearSelect = () => {
    setPointSelected('none');
    setSelection([]);
  };

  return (
    <Dialog
      aria-labelledby="explore-point-dialog"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='md'
      PaperComponent={DraggablePaper}
      PaperProps={{
        elevation: 2,
        sx: {
          position: "fixed",
          top: 85,
          left: -16,
          width: '500px',
          maxHeight: '80%',
          overflow: 'hidden',
          borderRadius: '6px',
        }
      }}
    >
      <DialogTitle
        id="explore-point-dialog"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          pr: 2
        }}
      >
        <Typography>Select Points of Interest to display</Typography>
        {selection.length > 0 &&
          <Tooltip title='Clear all selected points' placement='right'>
            <IconButton onClick={onClearSelect}>
              <DoDisturbIcon fontSize='small'/>
            </IconButton>
          </Tooltip>
        }
      </DialogTitle>
      <DialogContent sx={{ mb: 1, height: '100%', overflowY: 'scroll' }}>
        {groups.map((group, groupIdx) => {
          const groupData = points.filter(x => x.class === group);
          const groupSel = groupData.filter(x => selection.includes(x.type)).length;
          return (
            <Accordion
              key={`accordion-group-${groupIdx}`}
              expanded={expanded === group}
              onChange={onExpand(group)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Typography color={groupSel > 0 ? 'primary' : 'default'}>{group}</Typography>
                  {groupSel > 0 &&
                    <Box sx={{
                      width: '16px',
                      height: '16px',
                      borderRadius: '50%',
                      color: '#19E0FF',
                      border: 'solid 1px #19E0FF',
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      marginRight: '1em',
                      marginLeft: '1em',
                    }}
                    >
                      <Typography sx={{ marginTop: '2px', fontSize: '10px' }}>{groupSel}</Typography>
                    </Box>
                  }
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <List dense>
                  {groupData.map((value, valueIdx) => {
                      if (value.type !== 'All') {
                        return (
                          <Box key={valueIdx} sx={{ position: 'relative' }}>
                            <ListItem button dense onClick={() => onSelect(value.type)}>
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  size="small"
                                  color="primary"
                                  checked={selection.indexOf(value.type) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                />
                              </ListItemIcon>
                              <ListItemText primary={value.type} />
                            </ListItem>
                            {selection.indexOf(value.type) !== -1 ?
                              <Box sx={{
                                position: 'absolute',
                                top: 5,
                                right: 24,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                zIndex: 100
                              }}>
                                <IconButton
                                  size="small"
                                  onClick={() => setPointColor(value.type, getNextColor(value))}
                                >
                                  <PaletteIcon />
                                </IconButton>
                                <Box sx={{
                                  height: '10px',
                                  width: '10px',
                                  border: '1px solid #efefef',
                                  backgroundColor: 'color' in value ? value.color : DEFAULT_COLOUR
                                }} />
                              </Box>
                              :
                              null}
                          </Box>
                        )
                      } else {
                        return null
                      }
                    }
                  )}
                </List>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
