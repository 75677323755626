import React, { useState } from 'react';

import { useTheme, lighten } from '@mui/material/styles';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { FilterOptionsState } from '@mui/material/useAutocomplete';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import { Location } from "@/interfaces";


type LocationSearchBarProps = {
  options: Array<Location>;
  onSelect: (location: Location) => void;
  disabled?: boolean;
};

export const LocationSearchBar: React.FC<LocationSearchBarProps> = ({options, onSelect, disabled}) => {

  const theme = useTheme();

  const defaultLocation: Location = { geo_id: 0, geo_name: '', level: '', latitude: 0, longitude: 0}
  const [value, setValue] = useState<Location>(defaultLocation);

  const filter = createFilterOptions<Location>({
    ignoreCase: true,
    stringify: (option) => option.geo_name}
  );

  const onChange = (event: React.SyntheticEvent, newValue: Location | string | null) => {
    if (newValue) {
      if (typeof newValue !== 'string') {
        // setValue(newValue);
        onSelect(newValue);
      }
    } else {
      setValue(defaultLocation);
    }
  };

  const filterOptions = (options: Array<Location>, params: FilterOptionsState<Location>) => {
    if (params.inputValue.length > 0) {
      return filter(options, params);
    } else {
      return options;
    }
  };

  const getOptionLabel = (option: string | Location) => {
    if (typeof option === 'string') {
      return option
    } else {
      return option.geo_name
    }
  }

  return (
    <>
    <Autocomplete
      freeSolo
      multiple={false}
      disabled={disabled}
      value={value}
      onChange={onChange}
      options={options}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      // disableClearable
      selectOnFocus
      // clearOnBlur
      renderOption={(props, option: Location) => (
        <ListItem {...props} key={`${option.level}-${option.geo_name}`}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            <Chip size='small' label={option.level} sx={{ borderRadius: '4px', fontSize: '10px', height: '14px'}}/>
            <Typography variant='body2' sx={{ ml: 1 }}>{option.geo_name}</Typography>
          </Box>
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField
          //{...params}
          ref={params.InputProps.ref}
          color='secondary'
          size='small'
          disabled={disabled}
          sx={{
            width: '280px',
            backgroundColor: theme.palette.background.default,
            borderRadius: '6px',
            '& .Mui-disabled': {
              backgroundColor: lighten(theme.palette.background.default, 0.2)
            },
          }}
          inputProps={params.inputProps}
          InputProps={{
            sx: {
              borderRadius: '6px'
            }
          }}
          placeholder='Search for a Location'
        />
      )}
    />
      {/*<ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>*/}
      {/*  <Chip size='small' label={options[1].level} sx={{ borderRadius: '4px', fontSize: '10px', height: '14px'}}/>*/}
      {/*  <Typography sx={{ ml: 1 }}>{options[1].geo_name}</Typography>*/}
      {/*</ListItem>*/}
    </>
  )
}
