import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from "react-dnd-html5-backend";

import { useTheme } from "@mui/material/styles";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid';
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import RuleIcon from '@mui/icons-material/Rule';
import TuneIcon from '@mui/icons-material/Tune';
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import TableRowsIcon from '@mui/icons-material/TableRows';
import PlaceIcon from '@mui/icons-material/Place';

import pointFactData from "@/data/scores/point_facts.json";

import {
  ExploreFilterDialogNew,
  ExplorePointDialogNew,
  ExploreFilterSummaryDialog,
  ExploreFactDialog,
  ExplorePointFactDialog,
  ExploreInfoDialog,
} from '@/components/molecules';
import {
  Overlay,
  Tooltip,
  ButtonIcon,
  ExpandingChipList,
  ExpandingGroupedChipList,
  ExploreFilterMapLegend
} from "@/components/atoms";
import {
  ExploreFilterResult,
  Sector,
  ExploreVariable,
  ExploreFilter,
  ExploreFactResponse,
  ExploreData,
  MapResult,
  ExplorePoint,
  PointFact
} from "@/interfaces";
import { getData, getFacts, getFilterResult } from '@/services/ApiService';
import InfoIcon from "@mui/icons-material/Info";


function createLabel(variable: ExploreVariable): string {
  if (['Proximity', 'Demographics', 'Lifestyle'].includes(variable.group)) {
    return variable.category + ' | ' + variable.type
  } else {
    return variable.source + ' | ' + variable.type
  }
}


type FilterOverlayProps = {
  selected: Array<string|number>;
  point: string | number | null;
  pointFeatures: Array<ExplorePoint>;
  setPointSelected: (value: string) => void;
  setPointColor: (value: string, color: string) => void;
  updateMap: (data: MapResult) => void;
  clearMap: () => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
}

export const ExploreOverlay: React.FC<FilterOverlayProps> = (
  {
    selected,
    point,
    pointFeatures,
    setPointSelected,
    setPointColor,
    updateMap,
    clearMap,
    loading,
    setLoading
  }
) => {

  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState<ExploreData | null>(null);

  const [factOpen, setFactOpen] = useState<boolean>(false);
  const [resultsOpen, setResultsOpen] = useState<boolean>(false);
  const [infoOpen, setInfoOpen] = useState<boolean>(false);

  const pointFacts: Array<PointFact> = JSON.parse(JSON.stringify(pointFactData));
  const [pointFact, setPointFact] = useState<PointFact | undefined>(undefined);
  const [fact, setFact] = useState<ExploreFactResponse | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);

  const [filterSelection, setFilterSelection] = useState<Array<ExploreVariable>>([]);
  const [filterOperator, setFilterOperator] = useState<boolean>(true);
  const [filterResult, setFilterResult] = useState<Array<ExploreFilterResult> | undefined>( undefined);
  const [filterScores, setFilterScores] = useState<Array<Sector> | undefined>( undefined);

  const [pointOpen, setPointOpen] = useState<boolean>(false);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently()
      const response = await getData('filter', accessToken);
      response && setData(response);
    };
    fetchData().then(() => setLoading(false));
  }, []);

  useEffect(() => {
      const updateFacts = async () => {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();
        const response = await getFacts('sector', selected, accessToken);
        response && setFact(response);
      }
      if (selected.length > 0) {
        updateFacts().then(() => {
          setLoading(false);
          setPointFact(undefined);
          setFactOpen(true);
        }).catch((e) => {
          setLoading(false);
          setFact(undefined);
          setPointFact(undefined);
          setError('fact');
          setFactOpen(false);
        });
      } else {
        setFact(undefined);
        setPointFact(undefined);
      }
  }, [selected]);

  useEffect(() => {
    if (point) {
      const pointFact = pointFacts.filter((x: PointFact) => (x.id === Number(point)))
      if (pointFact.length > 0) {
        setPointFact(pointFact[0]);
        setFact(undefined);
        setFactOpen(true);
      } else {
        setPointFact(undefined);
        setFact(undefined);
        setFactOpen(false);
      }
    }
  },[point]);

  const handleFilterApply = (selection: Array<ExploreVariable>, operator: boolean) => {
    setFilterOpen(false);
    const callApi = async () => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const response = await getFilterResult(
        selection,
        operator ? 'AND' : 'OR',
        accessToken
      );
      setFilterSelection(selection);
      setFilterOperator(operator);
      setFilterResult(response.scores);
      setFilterScores(response.sectors);
      updateMap(response);
    }
    callApi().then(() => {
      setLoading(false);
      setFact(undefined);
      setPointFact(undefined);
      setFactOpen(false);
      setResultsOpen(true);
    });
  };

  const handleReset = () => {
    setFilterSelection([]);
    setFact(undefined);
    setPointFact(undefined);
    setFilterResult(undefined);
    setFilterScores(undefined);
    setFactOpen(false);
    setResultsOpen(false);
    clearMap();
  };

  const updateFilters = (filters: Array<ExploreFilter>) => {
    setData( existing => (existing && {...existing, filters: filters}));
  };

  const handleErrorClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(null);
  };

  const handleInfoOpen = () => {
    setInfoOpen(true);
  };

  const handleInfoClose = () => {
    setInfoOpen(false);
  };

  const handleFilterOpen = () => {
    setFilterOpen(true);
  };

  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  const handlePointOpen = () => {
    setPointOpen(true);
  };

  const handlePointClose = () => {
    setPointOpen(false);
  };

  const handleResultsOpen = () => {
    setResultsOpen(true);
  };

  const handleResultsClose = () => {
    setResultsOpen(false);
  };

  const handleFactOpen = () => {
    setFactOpen(true);
  };

  const handleFactClose = () => {
    setFactOpen(false);
  };

  return (
    <DndProvider backend={HTML5Backend}>
    <Box sx={{position: 'absolute', top: 16, width: '100%'}}>
      {loading && <Overlay />}
      <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', ml: 0, pr: 4}}>
        <Grid item container xs={8} spacing={2}>
          <Grid item>
            <Tooltip title='Apply a filter to the map' placement='bottom'>
              <Button
                variant="contained"
                color="primary"
                onClick={handleFilterOpen}
                endIcon={<TuneIcon />}
              >
                Filter
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title='Apply a filter to the map' placement='bottom'>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePointOpen}
                endIcon={<PlaceIcon />}
              >
                Overlay
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <ButtonIcon onClick={handleReset} color='primary' size='36px' Icon={DoDisturbIcon} tooltip='Clear All'/>
          </Grid>
          <Grid item>
            <ButtonIcon onClick={handleInfoOpen} color='primary' size='36px' Icon={InfoIcon} tooltip='More Info'/>
          </Grid>
          {filterSelection.length > 0 && (
            <Grid item xs={12}>
              <ExpandingChipList
                data={filterSelection}
                createLabel={createLabel}
                color='primary'
              />
            </Grid>
          )}

        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end'}}>
          <Stack spacing={2}>
            {filterResult &&
              <ButtonIcon onClick={handleResultsOpen} color='primary' size='36px' Icon={TuneIcon} tooltip='Show Filter Summary'/>
            }
            {fact &&
              <ButtonIcon onClick={handleFactOpen} color='primary' size='36px' Icon={TableRowsIcon} tooltip='Show Sector Scores'/>
            }
            {pointFact &&
              <ButtonIcon onClick={handleFactOpen} color='primary' size='36px' Icon={PlaceIcon} tooltip='Show Point Data'/>
            }
          </Stack>
        </Grid>
      </Grid>
      { data && pointFeatures &&
        <ExplorePointDialogNew
          open={pointOpen}
          points={pointFeatures}
          setPointSelected={setPointSelected}
          setPointColor={setPointColor}
          onClose={handlePointClose}
        />
      }
      { data &&
        <ExploreFilterDialogNew
          open={filterOpen}
          variables={data.variables}
          filters={data.filters}
          filterSelection={filterSelection}
          filterOperator={filterOperator}
          onCancel={handleFilterClose}
          onApply={handleFilterApply}
          updateFilters={updateFilters}
        />
      }
      {filterSelection && filterResult && resultsOpen &&
        <ExploreFilterSummaryDialog
          filterResult={filterResult}
          onClose={handleResultsClose}
        />
      }
      {fact && factOpen &&
        <ExploreFactDialog
          fact={fact}
          filterVariables={filterSelection}
          resultsOpen={resultsOpen}
          onClose={handleFactClose}
        />
      }
      {pointFact && factOpen &&
        <ExplorePointFactDialog
          fact={pointFact}
          resultsOpen={resultsOpen}
          onClose={handleFactClose}
        />
      }
      <ExploreInfoDialog open={infoOpen} onClose={handleInfoClose} />
      <Snackbar
        open={error !== null}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        sx={{ mb: 2, ml: 6 }}
      >
        <Alert
          onClose={handleErrorClose}
          color='error'
          severity='error'
          sx={{ background: theme.palette.background.default, borderRadius: '6px'}}
        >
          {error === 'fact'
            ? 'No facts exist for this sector.'
            : 'There has been an error. Please try again.'
          }
        </Alert>
      </Snackbar>
    </Box>
    </DndProvider>
  );
};
