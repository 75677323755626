import {CircleLayer, FillLayer, SymbolLayer} from "react-map-gl";

export const baseLayer: FillLayer = {
  id: 'base',
  type: 'fill',
  'source-layer': 'LAD_TCE',
  paint: {
    'fill-outline-color': 'rgba(0,0,0,0.1)',
    'fill-color': [
      'interpolate',
      ['linear'],
      ['feature-state', 'score'],
      0, '#EA673B',
      0.2, '#ED9173',
      0.4, '#F1C3B4',
      0.6, '#c3e7ec',
      0.8, '#62ccdc',
      1, '#00B1CC',
    ],
    'fill-opacity': [
      'case',
      ['>=', ['feature-state', 'score'], 0],
      1,
      0
    ]
  }
};

export const hoverLayer: FillLayer = {
  id: 'highlight',
  type: 'fill',
  'source-layer': 'LAD_TCE',
  paint: {
    'fill-outline-color': '#FFFFFF',
    'fill-color': '#FFFFFF',
    'fill-opacity': 0.3
  }
};

export const selectLayer: FillLayer = {
  id: 'select',
  type: 'fill',
  'source-layer': 'LAD_TCE',
  paint: {
    'fill-outline-color': '#725ba4',
    'fill-color': '#725ba4',
    'fill-opacity': 1.0
  }
};

export const clusterLayer: CircleLayer = {
  id: 'clusters',
  type: 'circle',
  source: 'points',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': ['step', ['get', 'point_count'], '#543b94', 50, '#725ba4', 250, '#ab9ec9'],
    'circle-radius': ['step', ['get', 'point_count'], 20, 50, 30, 250, 40],
  }
}

export const clusterCountLayer: SymbolLayer = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'points',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
  }
}

export const pointLayer: CircleLayer = {
  id: 'point',
  type: 'circle',
  source: 'points',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#543b94',
    'circle-radius': 8,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#fff'
  }
}
