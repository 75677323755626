import React, { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { CatchmentScoreGroup, CatchmentScore } from '@/interfaces';
import { formatCatchmentFact } from '@/utils/common';

const TOP_ONLY_CATEGORIES = ['Causes', 'Mindsets', 'Interests'];

function sortFactors(a: CatchmentScore, b: CatchmentScore) {
  const cols = ['Factor 1', 'Factor 2', 'Factor 3', 'Factor 4', 'Average', 'Weight', 'Decile']
  if (cols.indexOf(a.feature_name) < cols.indexOf(b.feature_name)) {
    return -1
  } else if (cols.indexOf(a.feature_name) < cols.indexOf(b.feature_name)) {
    return 1
  } else {
    return 0;
  }
}

type FactRow = {
  name: string;
  value: number;
  base: number;
  index: number;
};

function createRow(result: CatchmentScore): FactRow {
  return {
    name: result.feature_name,
    value: result.value,
    base: result.base,
    index: Number((Math.round(result.index * 100) / 100).toFixed(2))
  };
}

type CatchmentCompareCardProps = {
  group: CatchmentScoreGroup;
  groupIdx: number;
};

export const CatchmentCompareCard: React.FC<CatchmentCompareCardProps> = (
  {
    group,
    groupIdx
  }
) => {

  const theme = useTheme();
  const [expand, setExpand] = useState<boolean>(false);
  let scores = expand ? group.scores : group.scores.slice(0, 1);

  if (groupIdx == 0) {
    scores = scores.map(grp => ({...grp, scores: grp.scores.sort(sortFactors)}));
  }

  const onExpand = () => {
    setExpand(!expand);
  };

  return (
    <Card elevation={4} sx={{ mb: 3 }}>
      <CardHeader title={group.name.replace(/^\d+ /, '')} titleTypographyProps={{ variant: 'body1' }} sx={{ pb: 0 }}/>
      <CardContent>
        {scores.map((category, categoryIdx) => {
          const categoryScores = TOP_ONLY_CATEGORIES.includes(category.name)
            ? category.scores.slice(0, 10)
            : category.scores;
          return (
            <div key={`fact-table-${groupIdx}-${categoryIdx}`}>
              <Typography
                key={`category-${group.name}-${categoryIdx}`}
                variant='body2'
                color='primary'
                sx={{mt: categoryIdx > 0 ? 3 : 0}}
              >
                {category.name}
              </Typography>
              <Table size='small' padding='none'>
                <TableHead>
                  <TableRow>
                    <TableCell align='left' sx={{fontWeight: 400, fontSize: '12px', color: theme.palette.text.secondary, width: '45%', pt: 1, pb: 0.5}}>Name</TableCell>
                    <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', color: theme.palette.text.secondary, width: '20%', pt: 1, pb: 0.5}}>Baseline</TableCell>
                    <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', color: theme.palette.text.secondary, width: '20%', pt: 1, pb: 0.5}}>Catchment</TableCell>
                    <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', color: theme.palette.text.secondary, width: '15%', pt: 1, pb: 0.5}}>Index</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categoryScores.map((fact, factIdx) => {
                    const row = createRow(fact);
                    return (
                      <TableRow key={`fact-row-${groupIdx}-${categoryIdx}-${factIdx}`}>
                        <TableCell sx={{fontSize: '12px', py: 0.5, pr: 0.5}}>{row.name}</TableCell>
                        <TableCell align='center' sx={{fontSize: '12px', py: 0.5, color: theme.palette.text.secondary}}>{formatCatchmentFact(row.base, groupIdx === 0 ? 'Development Score' : category.name)}</TableCell>
                        <TableCell align='center' sx={{fontSize: '12px', py: 0.5}}>{formatCatchmentFact(row.value, groupIdx === 0 ? 'Development Score' : category.name)}</TableCell>
                        <TableCell
                          align='center'
                          sx={{
                            fontSize: '12px',
                            color: row.index >= 1 ? theme.palette.success.light : theme.palette.error.main
                          }}
                        >
                          {row.index}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </div>
          )})}
        {group.scores.length > 1 &&
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            {expand ?
              <Button size='small' color='secondary' onClick={onExpand}>Show Less</Button>
              :
              <Button size='small' color='secondary' onClick={onExpand}>Show More</Button>
            }
          </Box>
        }
      </CardContent>
    </Card>
  );
}
