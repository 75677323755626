import React  from 'react';

import Typography from '@mui/material/Typography';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

import { useTheme } from "@mui/material/styles";

import { ExploreVariableFact } from "@/interfaces";
import { formatExploreFact } from '@/utils/common';

type FactRow = {
  name: string;
  base: number | string;
  value: number | string;
  index: number;
};

function createRow(fact: ExploreVariableFact): FactRow {
  return {
    name: `${fact.category} | ${fact.type.replace(/^[0-9]+-? /m, '')}`,
    base: formatExploreFact(fact.base, fact.category),
    value: formatExploreFact(fact.value_avg, fact.category),
    index: Number((Math.round((fact.value_avg / fact.base) * 100) / 100).toFixed(2))
  };
}

type FactRelatedCardProps = {
  fact: Array<ExploreVariableFact>;
};

export const ExploreFactRelatedCard: React.FC<FactRelatedCardProps> = (
  {
    fact
  }
) => {

  const theme = useTheme();

  return (
    <Card elevation={4} sx={{ mb: 3 }}>
      <CardHeader title='Related Variables' titleTypographyProps={{ variant: 'body1' }} sx={{ pb: 0 }}/>
      <CardContent>
        {fact.length > 0 ?
          <Table size='small' padding='none'>
            <TableHead>
              <TableRow>
                <TableCell sx={{fontWeight: 400, fontSize: '12px', pb: 1, width: '40%', color: theme.palette.text.secondary}}>Name</TableCell>
                <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', pb: 1, width: '10%', color: theme.palette.text.secondary}}>UK Base</TableCell>
                <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', pb: 1, width: '10%', color: theme.palette.text.secondary}}>Value</TableCell>
                <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', pb: 1, width: '10%', color: theme.palette.text.secondary}}>Index</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fact.map((variable, variableIdx) => {
                const row = createRow(variable);
                return (
                  <TableRow key={`fact-row-${variableIdx}`}>
                    <TableCell align='left' sx={{fontSize: '12px', py: 1}}>{row.name}</TableCell>
                    <TableCell align='center' sx={{
                      fontSize: '12px',
                      color: theme.palette.text.secondary
                    }}>
                      {row.base}
                    </TableCell>
                    <TableCell align='center' sx={{
                      fontSize: '12px',
                    }}>
                      {row.value}
                    </TableCell>
                    <TableCell align='center' sx={{
                      fontSize: '12px',
                      color: row.index > 1 ? theme.palette.success.light : row.index == 1 ? theme.palette.text.primary :theme.palette.error.main
                    }}>
                      {row.index}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          :
          <Typography
            variant='body2'
            color='textSecondary'
            align='center'
          >
            No related variables available for this Sector
          </Typography>
        }
      </CardContent>
    </Card>

  );
}
