import React from 'react';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";

function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#legend"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export const MapLegend = () => {

  const theme = useTheme();

  return (
    <DraggablePaper
      id='legend'
      elevation={0}
      sx={{
        position: "fixed",
        bottom: 40,
        left: 32,
        width: '150px',
        background: 'transparent'
      }}
    >
      <Box>
        <Typography variant='caption' color={theme.palette.primary.contrastText} gutterBottom>
          Percentile
        </Typography>
      </Box>
      <Box sx={{
        width: '150px',
        height: '10px',
        borderRadius: '10px',
        backgroundImage: 'linear-gradient(to right, #EA673B, #F4F4F4, #00B1CC)'
      }}/>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography variant='caption' color={theme.palette.primary.contrastText}>0</Typography>
        <Typography variant='caption' color={theme.palette.primary.contrastText}>50</Typography>
        <Typography variant='caption' color={theme.palette.primary.contrastText}>100</Typography>
      </Box>
    </DraggablePaper>
  );
}
