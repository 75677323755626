import React, { useState, useCallback, useMemo } from 'react';
import { times } from 'lodash';

import Map, { Source, Layer, Popup, NavigationControl, AttributionControl, MapboxGeoJSONFeature } from 'react-map-gl';
import { MapboxEvent } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import Typography from '@mui/material/Typography';

import { SimpleOverlay } from '@/components/molecules';
import { SimpleFeatures, SimpleFact, SimplePointFeature, SimplePoint } from '@/interfaces';
import { baseLayer, hoverLayer, selectLayer, clusterLayer, clusterCountLayer, pointLayer } from './SimpleLayers';

const MAP_STYLE = 'mapbox://styles/mpilarczykstarcount/cl3c3adtf001214ndmp4jcmn8';

type SimpleMapProps = {
  features: SimpleFeatures;
  facts: Array<SimpleFact>;
  points: Array<SimplePointFeature>;
}

export const SimpleMap: React.FC<SimpleMapProps> = (
  {
    features,
    facts,
    points
  }
) => {

  const [selected, setSelected] = useState<MapboxGeoJSONFeature | undefined>(undefined);
  const [hovered, setHovered] = useState<number>(0);
  const [cursor, setCursor] = useState<string>('auto');
  const [fact, setFact] = useState<SimpleFact | undefined>(undefined);
  const [point, setPoint] = useState<SimplePoint | undefined>(undefined);

  const onLoad = useCallback((event: MapboxEvent) => {
    const map = event.target;
    [...times(380)].forEach(value => {
      map.setFeatureState({id: value, source: 'lad-source', sourceLayer: 'LAD_TCE'}, {score: -1});
    });
  }, []);

  const onClick = useCallback(event => {
    const feature = event.features && event.features[0];
    if (feature) {
      if (feature.layer.id === 'base') {
        setSelected(feature);
        setFact(facts.filter((fact) => fact.id === feature.id)[0]);
      } else if (feature.layer.id === 'point') {
        setPoint({...feature.properties});
      }
    } else {
      clearSelected();
      setPoint(undefined);
    }
  }, [facts]);

  const onHover = useCallback(event => {
    const feature = event.features && event.features[0];
    feature && feature.layer.id === 'base' && setHovered(feature.id);
  }, []);

  const clearSelected = () => {
    setSelected(undefined);
    setFact(undefined);
    setPoint(undefined);
  };

  const onMouseEnter = useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = useCallback(() => setCursor('auto'), []);

  const hoverFilter = useMemo(() => ['==', ['id'], hovered], [hovered]);
  const selectFilter = useMemo(() => ['==', ['id'], selected ? selected.id : 0], [selected]);

  return (
    <Map
      initialViewState={{
        longitude: -4,
        latitude: 53.14,
        zoom: 5.77
      }}
      minZoom={4}
      mapStyle={MAP_STYLE}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_MAP_TOKEN}
      cursor={cursor}
      // onLoad={onLoad}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onMouseMove={onHover}
      interactiveLayerIds={['base', 'point']}
      // interactiveLayerIds={['unclustered-point']}
      attributionControl={false}
    >
      <AttributionControl
        customAttribution='Contains public sector information licensed under the Open Government Licence v3.0.'
      />
      <NavigationControl
        position='bottom-right'
        showCompass={false}
        visualizePitch={false}
        style={{ marginBottom: '16px', marginRight: '16px' }}
      />
      <Source id='lad-source' type='vector' url='mapbox://mpilarczykstarcount.LAD_TCE'>
        <Layer beforeId="waterway-label" {...baseLayer} />
        <Layer beforeId="waterway-label" {...hoverLayer} filter={hoverFilter}/>
        <Layer beforeId="waterway-label" {...selectLayer} filter={selectFilter}/>
      </Source>
      <Source
        id='points'
        type='geojson'
        data='/data/points/empty/points_empty.geojson'
        cluster={true}
        clusterMaxZoom={14}
        clusterRadius={60}
        clusterMinPoints={5}
      >
        <Layer {...clusterLayer} />
        <Layer {...clusterCountLayer} />
        <Layer {...pointLayer} />
      </Source>
      <SimpleOverlay features={features} pointFeatures={points} fact={fact} clear={clearSelected}/>
      {point &&
        <Popup
          latitude={point.latitude}
          longitude={point.longitude}
          closeButton={false}
          onClose={() => setPoint(undefined)}
        >
          <Typography>{point.name}</Typography>
        </Popup>
      }
    </Map>
  );
}
