import React from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Route, Switch } from 'react-router-dom';

import { Landing } from '@/pages/Landing';
import { Simple } from '@/pages/Simple';
import { Catchment } from '@/pages/Catchment';
import { Explore } from '@/pages/Explore';
import { Spinner } from '@/components/atoms';

function App() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Switch>
      <Route path="/" exact component={withAuthenticationRequired(Landing)} />
      <Route path="/identify" exact component={withAuthenticationRequired(Simple)} />
      <Route path="/catchment" exact component={withAuthenticationRequired(Catchment)} />
      <Route path="/explore" exact component={withAuthenticationRequired(Explore)} />
    </Switch>
  );
}

export default App;
