import React, { useState } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";


type LoadTableProps = {
  rows: Array<any>;
  columns: GridColDef[];
};

export const LoadTable: React.FC<LoadTableProps> = ({rows, columns}) => {

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      disableColumnMenu
      autoPageSize
      hideFooterSelectedRowCount
      rowHeight={36}
      sx={{
        border: 'none',
        '& .MuiDataGrid-iconSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-cell': {
          borderBottom: 'none'
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none'
        },
        '& .MuiDataGrid-cell:focus-within': {
          outline: 'none'
        },
        '& .MuiDataGrid-columnHeader:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-withBorder': {
          borderRight: 'none',
        },
        '& .MuiDataGrid-cellContent': {
          fontSize: 12,
        },
      }}
    />
  );

}
