import React from "react";

import StarIcon from "@mui/icons-material/Star";
import PeopleIcon from '@mui/icons-material/People';
import HouseIcon from '@mui/icons-material/House';
import InterestsIcon from '@mui/icons-material/Interests';
import PsychologyIcon from '@mui/icons-material/Psychology';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SchoolIcon from '@mui/icons-material/School';
import PowerIcon from '@mui/icons-material/Power';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TrainIcon from '@mui/icons-material/Train';
import CircleIcon from '@mui/icons-material/Circle';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import NightShelterIcon from '@mui/icons-material/NightShelter';
import WorkIcon from '@mui/icons-material/Work';


import {SvgIconProps} from "@mui/material";

export const getIconFromName = (groupName: string, props: SvgIconProps) => {

  switch (groupName) {
    case 'Crown Estate Asset':
      return <StarIcon {...props}/>
    case 'Demographics':
      return <PeopleIcon {...props}/>
    case 'Housing':
      return <HouseIcon {...props}/>
    case 'Interests':
      return <InterestsIcon {...props}/>
    case 'Mindsets':
      return <PsychologyIcon {...props}/>
    case 'Causes':
      return <VolunteerActivismIcon {...props}/>
    case 'Social Economics':
      return <TrendingUpIcon {...props}/>
    case 'Education':
      return <SchoolIcon {...props}/>
    case 'Infrastructure':
      return <PowerIcon {...props}/>
    case 'Leisure & Retail':
      return <ShoppingCartIcon {...props}/>
    case 'Transport':
      return <TrainIcon {...props}/>
    case 'Proximity - Driving':
      return <DirectionsCarIcon {...props}/>
    case 'Proximity - Walking':
      return <DirectionsWalkIcon {...props}/>
    case 'Proximity - Other':
      return <TransferWithinAStationIcon {...props}/>
    case 'Workplace':
      return <WorkIcon {...props}/>
    default:
      return <CircleIcon {...props}/>
  }
}

export const getIconFromDQS = (name: string, props: SvgIconProps) => {

  switch (name) {
    case 'Life Sciences & Innovation':
      return <VaccinesIcon {...props}/>
    case 'Single Family Housing':
      return <FamilyRestroomIcon {...props}/>
    case 'Build to Rent':
      return <NightShelterIcon {...props}/>
    case 'Energy Enterprise Parks':
      return <ElectricBoltIcon {...props}/>
    default:
      return <CircleIcon {...props}/>
  }
}
