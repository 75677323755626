import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { SimpleMap } from '@/components/molecules';
import { SimpleResponse } from '@/interfaces';
import { Loading } from '@/pages/Loading';

import { getData } from '@/services/ApiService';
import {PageLayout} from "@/layouts/PageLayout";


export const Simple: React.FC = () => {

  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState<SimpleResponse | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  useEffect(() => {

    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently()
      const response = await getData('simple', accessToken);
      response && setData(response);
    };

    fetchData().then(() => setIsLoading(false));

  }, [getAccessTokenSilently]);

  if (data && !isLoading) {
    return (
      <PageLayout title='Identify'>
        <div className='map-area'>
          <SimpleMap features={data.features} facts={data.facts} points={data.points}/>
        </div>
      </PageLayout>
    )
  } else {
    return (
      <Loading />
    )
  }
}
