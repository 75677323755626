import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import { FactPointValue } from './FactPointValue';

type FactPointProps = {
  data: any;
};

export const FactPoint: React.FC<FactPointProps> = ({
  data
}) => {

  const keys = Object.keys(data);

  return (
    <Grid item xs={12} container spacing={2}>
      {keys.map((key) => {
        const subData = data[key];
        return (
          <Grid item xs={12} key={`point-fact-${key}`}>
            <Card elevation={4} key={`point-fact-${key}`}>
              <CardHeader title={key} titleTypographyProps={{ variant: 'body1', fontFamily: 'cera-pro-bold'}} sx={{ pb: 0 }}/>
              <CardContent>
                <FactPointValue
                    scores={data[key]}
                    source={key}
                    conversions={{
                      'Undergraduate Subjects': 'rate',
                      'Postgraduate Subjects': 'rate',
                      'Finance': 'currency'
                    }}
                  />
              </CardContent>
            </Card>
          </Grid>
          // <Grid item xs={12} mt={2} key={`point-fact-${key}`}>
          //   <Typography
          //     sx={{
          //       fontFamily: 'cera-pro-bold',
          //       textTransform: 'uppercase',
          //       mb: 1,
          //       mt: 1,
          //       fontSize: '14px'
          //     }}
          //   >
          //     {key}
          //   </Typography>
          //   <FactPointValue
          //     scores={data[key]}
          //     source={key}
          //     conversions={{
          //       'Undergraduate Subjects': 'rate',
          //       'Postgraduate Subjects': 'rate',
          //       'Finance': 'currency'
          //     }}
          //   />
          // </Grid>
        );
      })}
    </Grid>
  );
};
