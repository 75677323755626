

export const formatSimpleFact = (value: number, category: string) => {
  switch (category) {
    case 'Population':
      return toDensity(value);
    case 'Age':
      return toPercentage(value);
    case 'Gender':
      return toPercentage(value);
    case 'Lifestage':
      return toPercentage(value);
    case 'Household Size':
      return toPercentage(value);
    case 'UK Tenure':
      return toPercentage(value);
    case 'Deprivation Dimensions':
      return toPercentage(value);
    case 'Income':
      return toCurrency(value);
    case 'Employment':
      return toPercentage(value);
    case 'Top Interests':
      return toPercentage(value);
    case 'Top Mindsets':
      return toPercentage(value);
    case 'Top Causes':
      return toPercentage(value);
    case 'Home Ownership':
      return toPercentage(value);
    case 'House Price':
      return toBigCurrency(value);
    case 'House Price Growth':
      return toPercentage(value);
    case 'Rental Price':
      return toCurrency(value);
    case 'House Stock':
      return toPopDensity(value);
    case 'House Sales':
      return toPopDensity(value);
    case 'New Builds':
      return toPopDensity(value);
    case 'Electric Vehicles Grants':
      return toBigCurrency(value);
    case 'Walking / Cycling':
      return toPercentage(value);
    case 'Workplace Size':
      return toRoundedIfZero(value);
    case 'Workplace Industry':
      return toRoundedIfZero(value);

    default:
      return Number((Math.round(value * 100) / 100).toFixed(2));
  }
};

export const formatCatchmentFact = (value: number, category: string) => {
  switch (category) {
    case 'Top Scoring':
      return toLongDecimal(value);
    case 'Population':
      return toDensity(value);
    case 'Age Band':
      return toPercentage(value);
    case 'Gender':
      return toPercentage(value);
    case 'Income Band':
      return toPercentage(value);
    case 'Lifestage':
      return toPercentage(value);
    case 'Household Size':
      return toPercentage(value);
    case 'UK Tenure':
      return toPercentage(value);
    case 'Deprivation Dimensions':
      return toPercentage(value);
    case 'Interests':
      return toDensity(value);
    case 'Mindsets':
      return toDensity(value);
    case 'Causes':
      return toDensity(value);
    case 'Home Ownership':
      return toPercentage(value);
    case 'Price':
      return toBigCurrency(value);
    case '10 Year Growth':
      return toPercentage(value);
    case '5 Year Growth':
      return toPercentage(value);
    case 'Rental Price':
      return toCurrency(value);
    case 'House Stock':
      return toPopDensity(value);
    case 'Sales':
      return toPopDensity(value);
    case 'Sold Properties':
      return toPopDensity(value);
    case 'New Builds':
      return toPopDensity(value);
    case 'Driving':
      return toPopDensity(value);
    case 'Walking':
      return toPopDensity(value);
    case 'Number of Businesses':
      return toLongDecimal(value);
    case 'Workplace Industry':
      return toLongDecimal(value);
    case 'Growth of Crime':
      return toPercentage(value);
    case 'Number of Crimes':
      return toLongDecimal(value);
    case 'Development Score':
      return toRoundedIfZero(value);

    default:
      return Number((Math.round(value * 100) / 100).toFixed(3));
  }
};

export const formatExploreFact = (value: number, category: string) => {
  switch (category) {
    case 'Top Scoring':
      return toLongDecimal(value);
    case 'Population':
      return toDensity(value);
    case 'Age Band':
      return toPercentage(value);
    case 'Gender':
      return toPercentage(value);
    case 'Income Band':
      return toPercentage(value);
    case 'Lifestage':
      return toPercentage(value);
    case 'Household Size':
      return toPercentage(value);
    case 'UK Tenure':
      return toPercentage(value);
    case 'Deprivation Dimensions':
      return toPercentage(value);
    case 'Interests':
      return toDensity(value);
    case 'Mindsets':
      return toDensity(value);
    case 'Causes':
      return toDensity(value);
    case 'Home Ownership':
      return toPercentage(value);
    case 'Price':
      return toBigCurrency(value);
    case 'House Prices':
      return toBigCurrency(value);
    case '10 Year Growth':
      return toPercentage(value);
    case '5 Year Growth':
      return toPercentage(value);
    case 'Rental Price':
      return toCurrency(value);
    case 'House Stock':
      return toPopDensity(value);
    case 'House Stock (per person per km)':
      return toLongDecimal(value, 2);
    case 'Sales':
      return toPopDensity(value);
    case 'Sales (per person per km)':
      return toLongDecimal(value, 2);
    case 'Sold Properties':
      return toPopDensity(value);
    case 'Sold Properties (per person per km)':
      return toLongDecimal(value, 2);
    case 'New Builds':
      return toPopDensity(value);
    case 'New Builds (per person per km)':
      return toLongDecimal(value, 2);
    case 'Driving':
      return (value > 100) ? toRounded(value) : toRoundedIfZero(value);
    case 'Walking':
      return (value > 100) ? toRounded(value) : toRoundedIfZero(value);
    case 'Number of Businesses':
      return toLongDecimal(value);
    case 'Number of Businesses (per person per km)':
      return toLongDecimal(value);
    case 'Workplace Industry':
      return toLongDecimal(value);
    case 'Workplaces by Industry (per person per km)':
      return toLongDecimal(value);
    case 'Growth of Crime':
      return toPercentage(value);
    case 'Number of Crimes':
      return toLongDecimal(value);
    case 'Number of Crimes (per person per km)':
      return toLongDecimal(value);

    default:
      return Number((Math.round(value * 100) / 100).toFixed(3));
  }
};

export const toPercentage = (value: number) => {
  return `${(value * 100).toFixed(1)}%`;
};

export const toCurrency = (value: number) => {
  return `£${value.toFixed(0)}`;
};

export const toPopDensity = (value: number) => {
  return `${value.toFixed(1)}`;
};

export const toDensity = (value: number) => {
  return `${value.toFixed(1)}`;
};

export const toLongDecimal = (value: number, digits: number = 3) => {
  return value.toFixed(digits);
}

export const toRounded = (value: number) => {
  return Math.round(value);
}

export const toRoundedIfZero = (value: number) => {
  return (Math.round(value) === value) ? Math.round(value) : value.toFixed(1);
}

export const toBigCurrency = (value: number) => {

  var SI_SYMBOL = ['', 'k', 'M', 'B', 'T', 'P', 'E'];

  // what tier? (determines SI symbol)
  var tier = Math.log10(Math.abs(value)) / 3 | 0;

  // if zero, we don't need a suffix
  if(tier === 0) return value;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = value / scale;

  // format number and add suffix
  return `£${scaled.toFixed(1) + suffix}`;
}

export const addThousandSep = (value: number) => {
  const thousandRegex = /(\d)(?=(\d{3})+(?!\d))/g;
  const value_str = value.toFixed(0);
  let index = value_str.search(/[1-9]/);
  index = index === -1 ? value_str.length : index;
  return (
    value_str.substring(0, index) +
    value_str.substring(index, value_str.length).replace(thousandRegex, '$1,')
  );
};
