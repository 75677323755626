import React, { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { SimpleFactGroup, SimpleFactScore } from '@/interfaces';
import { formatSimpleFact } from '@/utils/common';

type FactRow = {
  name: string;
  value: number;
  base: number;
  index: number;
};

function createRow(fact: SimpleFactScore): FactRow {
  return {
    name: fact.feature_name,
    value: fact.value,
    base: fact.base,
    index: Number((Math.round(fact.index * 100) / 100).toFixed(2))
  };
}

type SimpleFactCardProps = {
  factGroup: SimpleFactGroup;
  factGroupIdx: number;
};

export const SimpleFactCard: React.FC<SimpleFactCardProps> = (
  {
    factGroup,
    factGroupIdx
  }
) => {

  const theme = useTheme();
  const [expand, setExpand] = useState<boolean>(false);
  const scores = expand ? factGroup.scores : factGroup.scores.slice(0, 1);

  const onExpand = () => {
    setExpand(!expand);
  };

  return (
    <Card elevation={4} sx={{ mb: 3 }}>
      <CardHeader title={factGroup.name} titleTypographyProps={{ variant: 'body1' }} sx={{ pb: 0 }}/>
      <CardContent>
        {scores.map((category, categoryIdx) => (
          <div key={`fact-table-${factGroupIdx}-${categoryIdx}`}>
            <Typography
              key={`category-${factGroup.name}-${categoryIdx}`}
              variant='body2'
              color='primary'
              sx={{ mt: categoryIdx > 0 ? 3 : 0 }}
            >
              {category.name}
            </Typography>
            <Table size='small' padding='none'>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 400, fontSize: '12px', color: theme.palette.text.secondary, width: '55%', pt: 1, pb: 0.5 }}>Name</TableCell>
                  <TableCell sx={{ fontWeight: 400, fontSize: '12px', color: theme.palette.text.secondary, width: '15%', pt: 1, pb: 0.5 }}>Base</TableCell>
                  <TableCell sx={{ fontWeight: 400, fontSize: '12px', color: theme.palette.text.secondary, width: '15%', pt: 1, pb: 0.5 }}>Value</TableCell>
                  <TableCell sx={{ fontWeight: 400, fontSize: '12px', color: theme.palette.text.secondary, width: '15%', pt: 1, pb: 0.5 }}>Index</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {category.scores.map((fact, factIdx) => {
                  const row = createRow(fact);
                  return (
                    <TableRow key={`fact-row-${factGroupIdx}-${categoryIdx}-${factIdx}`}>
                      <TableCell sx={{ fontSize: '12px', py: 0.5, pr: 0.5 }}>{row.name}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 0.5, color: theme.palette.text.secondary }}>{formatSimpleFact(row.base, category.name)}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 0.5 }}>{formatSimpleFact(row.value, category.name)}</TableCell>
                      <TableCell
                        sx={{
                          fontSize: '12px',
                          color: row.index >= 1 ? theme.palette.success.light : theme.palette.error.main
                        }}
                      >
                        {row.index}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        ))}
        {factGroup.scores.length > 1 &&
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            {expand ?
              <Button size='small' color='info' onClick={onExpand}>Show Less</Button>
              :
              <Button size='small' color='info' onClick={onExpand}>Show More</Button>
            }
          </Box>
        }
      </CardContent>
    </Card>
  );
}
