import React from 'react';
import Draggable from 'react-draggable';

import Paper, { PaperProps } from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';


import { SimpleFact } from '@/interfaces';
import { SimpleFactCard } from '@/components/molecules';
import { toTopFacts } from '@/utils/facts';


function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#simple-fact-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type SimpleFactDialogProps = {
  fact: SimpleFact;
  onClose: () => void;
};


export const SimpleFactDialog: React.FC<SimpleFactDialogProps> = ({
    fact,
    onClose,
  }) => {

  return (
    <DraggablePaper
      elevation={2}
      sx={{
        position: "fixed",
        top: 80,
        right: 62,
        width: '500px',
        height: 'calc(100% - 82px - 36px)',
        overflow: 'hidden',
        borderRadius: '6px',
    }}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden'
        }}
      >
        <CardHeader id='simple-fact-dialog' title={fact.name} sx={{ pb: 0 }}/>
        <CardContent sx={{ overflowY: 'auto' }} >
          {fact.scores.map((group, groupIdx) => (
            ['Interests', 'Mindsets', 'Causes'].includes(group.name) ?
            <SimpleFactCard key={`fact-card-${groupIdx}`} factGroup={toTopFacts(group)} factGroupIdx={groupIdx}/>
            :
            <SimpleFactCard key={`fact-card-${groupIdx}`} factGroup={group} factGroupIdx={groupIdx}/>
          ))}
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button color='info' onClick={onClose}>
            Close
          </Button>
        </CardActions>
      </Card>
    </DraggablePaper>
  );
}
