import React, { useState, useEffect } from 'react';
import { useMap, GeoJSONSource } from "react-map-gl";
import { useAuth0 } from "@auth0/auth0-react";

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TuneIcon from '@mui/icons-material/Tune';
import PlaceIcon from '@mui/icons-material/Place';
import InfoIcon from '@mui/icons-material/Info';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

import { SimpleSelectDialog, SimplePointDialog, SimpleFactDialog, SimpleInfoDialog } from '@/components/molecules';
import { ExpandingChipList, ButtonIcon, MapLegend, Tooltip } from '@/components/atoms';
import {
  SimpleFeatures,
  SimplePointFeature,
  SimpleFact,
  SimpleVariable,
  SimpleScore
} from "@/interfaces";
import { getScores } from '@/services/ApiService';
import {times} from "lodash";

const defaultSelection = [
  {category: 'Population Density', name: 'Population Density', id: 1},
];

function createLabel(variable: SimpleVariable): string {
  return variable.category.replace(/ \(.*\)/i, '') + ' | ' + variable.name
}

type SimpleOverlayProps = {
  features: SimpleFeatures;
  pointFeatures: Array<SimplePointFeature>;
  fact?: SimpleFact;
  clear: () => void;
}

export const SimpleOverlay: React.FC<SimpleOverlayProps> = ({features, pointFeatures, fact, clear}) => {

  const { current: map } = useMap();
  const { getAccessTokenSilently } = useAuth0();
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const [pointOpen, setPointOpen] = useState<boolean>(false);
  const [factOpen, setFactOpen] = useState<boolean>(false);
  const [infoOpen, setInfoOpen] = useState<boolean>(false);
  const [selectedFeatures, setSelectedFeatures] = useState<Array<SimpleVariable>>(defaultSelection);
  const [selectedPoint, setSelectedPoint] = useState<string>('');
  const [scores, setScores] = useState<Array<SimpleScore>>([]);

  const selectFeatures = {groups: features.groups.slice(0,), groupData: features.groupData.slice(0,), groupIcons: features.groupIcons}

  useEffect(() => {
    map && scores.forEach(value => {
      map.setFeatureState(
        {id: value.geo_id, source: 'lad-source', sourceLayer: 'LAD_TCE'},
        {score: value.score}
      );
    });
  }, [map, scores]);

  useEffect(() => {
    fact ? setFactOpen(true) : setFactOpen(false);
  }, [fact]);

  useEffect(() => {
    const updateScores = async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await getScores(
        'local_authority_districts',
        selectedFeatures.map(x => x.id).join('|'),
        accessToken
      );
      setScores(response);
    };
    selectedFeatures.length > 0 && updateScores().then();
  }, [selectedFeatures, getAccessTokenSilently]);

  const handleSelectOpen = () => {
    setSelectOpen(true);
  };

  const handleSelectClose = () => {
    setSelectOpen(false);
  };

  const handlePointOpen = () => {
    setPointOpen(true);
  };

  const handlePointClose = () => {
    setPointOpen(false);
  };

  const handleInfoOpen = () => {
    setInfoOpen(true);
  };

  const handleInfoClose = () => {
    setInfoOpen(false);
  };

  const handleFactClose = () => {
    setFactOpen(false);
    clear();
  };

  const updateSelection = async (newSelection: Array<SimpleVariable>) => {
    setSelectedFeatures(newSelection);
    // const accessToken = await getAccessTokenSilently();
    // const response = await getScores(
    //   'local_authority_districts',
    //   newSelection.map(x => x.id).join('|'),
    //   accessToken
    // );
    // setScores(response);
    handleSelectClose();
  };

  const updatePoint = (group: string, point: string) => {
    setSelectedPoint(point);
    updatePointSource(group, point);
    handlePointClose();
    clear();
  };

  const updatePointSource = (folder: string, key: string) => {
    const dir = folder.replace(/\s+/g, '_').toLowerCase();
    const label = key.replace(/\s+/g, '_').toLowerCase();
    const source: GeoJSONSource | undefined = map && (map.getSource('points') as GeoJSONSource);
    source && source.setData(`/data/points/${dir}/points_${label}.geojson`);
  };

  const resetMap = () => {
    map && [...times(380)].forEach(value => {
      map.setFeatureState({id: value, source: 'lad-source', sourceLayer: 'LAD_TCE'}, {score: -1});
    });
  };

  const handleClear = () => {
    setSelectedFeatures([]);
    setScores([]);
    setSelectedPoint('');
    updatePointSource('empty', 'empty');
    setSelectOpen(false);
    setFactOpen(false);
    resetMap();
  };

  return (
    <>
      <Grid container columnSpacing={4} rowSpacing={2} sx={{ display: 'flex', mt: 2, ml:0, width: '100%'}}>
        <Grid item xs={2} sx={{justifyContent:'flex-end'}}>
          <Tooltip title='Select scores to show on the map' placement='bottom'>
            <Button
              fullWidth
              variant="contained"
              color="info"
              onClick={handleSelectOpen}
              endIcon={<TuneIcon />}
            >
              Selection
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          <Tooltip title='Select Point entities to overlay' placement='bottom'>
            <Button
              fullWidth
              variant="contained"
              color="info"
              onClick={handlePointOpen}
              endIcon={<PlaceIcon />}
            >
              Overlay
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ mr: 4 }}>
            <ButtonIcon onClick={handleInfoOpen} color='info' size='36px' Icon={InfoIcon} tooltip='More Info'/>
          </Box>
          { (selectedFeatures.length > 0 || selectedPoint !== '') &&
            <ButtonIcon onClick={handleClear} color='info' size='36px' Icon={DoDisturbIcon} tooltip='Clear All'/>
          }
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={4}>
          { selectedFeatures.length > 0 && <ExpandingChipList data={selectedFeatures} createLabel={createLabel} color='info' /> }
        </Grid>
      </Grid>
      <MapLegend />
      <SimpleSelectDialog
        open={selectOpen}
        data={selectFeatures}
        selection={selectedFeatures}
        onCancel={handleSelectClose}
        onApply={updateSelection}
      />
      <SimplePointDialog
        open={pointOpen}
        data={pointFeatures}
        selected={selectedPoint}
        onCancel={handlePointClose}
        onApply={updatePoint}
      />
      <SimpleInfoDialog
        open={infoOpen}
        onClose={handleInfoClose}
      />
      {factOpen && fact && (
        <SimpleFactDialog
          fact={fact}
          onClose={handleFactClose}
        />
      )}
    </>
  );
};
