import React from 'react';

import Box from '@mui/material/Box';
import {Link} from "react-router-dom";

import { StarcountLogo, TCELogo } from '@/assets/logos';

type LogoButtonProps = {
  route: string;
}

export const LogoButton = ({ route }: LogoButtonProps) => {

  return (
    <Link to={route} style={{ textDecoration: 'none' }}>
    <Box sx={{ display: 'flex', flexDirection: 'row'}}>
      <Box sx={{ flexGrow: 0, width: '148px', height: '32px', mr: 2}}>
        <StarcountLogo />
      </Box>
    </Box>
    </Link>
  );
}
