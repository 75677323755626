import React, {useState, useEffect} from 'react';
import Draggable from 'react-draggable';
import { cloneDeep } from 'lodash';

import Dialog  from '@mui/material/Dialog';
import DialogTitle  from '@mui/material/DialogTitle';
import DialogContent  from '@mui/material/DialogContent';
import DialogActions  from '@mui/material/DialogActions';
import Box  from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from "@mui/material/Chip";
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


import { SimpleTabPanel } from '@/components/molecules';
import {SimpleFeatures, SimpleVariable} from '@/interfaces';


function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#simple-select-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type SimpleSelectDialogProps = {
  open: boolean;
  data: SimpleFeatures;
  selection: Array<SimpleVariable>;
  onCancel: () => void;
  onApply: (selectedVariables: Array<SimpleVariable>) => void;
};


export const SimpleSelectDialog: React.FC<SimpleSelectDialogProps> = (
  {
    open,
    data,
    selection,
    onCancel,
    onApply
  }) => {

  const [selectedVariables, setSelectedVariables] = useState<Array<SimpleVariable>>(cloneDeep(selection));
  useEffect(() => { setSelectedVariables(cloneDeep(selection)) }, [selection]);

  const onSelect = (variable: SimpleVariable) => {
    const update = [...selectedVariables, variable];
    setSelectedVariables(update);
  };

  const onDelete = (value: number) => {
    const update = selectedVariables.filter((v) => v.id !== value);
    setSelectedVariables(update);
  };

  const onClear = () => {
    setSelectedVariables([]);
  }

  const onClose = () => {
    onCancel();
    setSelectedVariables(selection);
  };

  return (
    <Dialog
      aria-labelledby="simple-select-dialog"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='md'
      PaperComponent={DraggablePaper}
      PaperProps={{
        elevation: 2,
        sx: {
          position: "fixed",
          top: 100,
          left: 0,
          height: '80%',
          overflow: 'hidden',
          borderRadius: '6px',
        }
      }}
    >
      <DialogTitle
        id="simple-select-dialog"
      >
        Select a variable to update the heatmap
      </DialogTitle>
      <DialogContent sx={{ mb: 1, height: '100%', overflowY: 'scroll'}}>
        <SimpleTabPanel data={data} selectedVariables={selectedVariables} onSelect={onSelect}/>
      </DialogContent>
      <Divider variant="middle" />
      <DialogContent sx={{ overflow: 'visible', height: '116px' }}>
        <Typography variant='body2' mb={1}>Selected Variables</Typography>
        <Box sx={{display: 'block', position: 'relative'}}>
        <Paper
          elevation={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            p: 1,
            boxShadow: 'none',
            minHeight: '40px'
          }}
        >
          {selectedVariables.map((variable, variableIdx) => (
            <Chip
              key={`variable-chip-${variableIdx}`}
              label={`${variable.category.replace(/ \(.*\)/i, '')} | ${variable.name}`}
              color='primary'
              size='small'
              onDelete={() => onDelete(variable.id)}
              sx={{ borderRadius: '4px', mr: 1, mb: 1}}
            />
          ))}
          <IconButton onClick={onClear} sx={{position: 'absolute', right: 0, top: 0}}>
            <CloseIcon />
          </IconButton>
        </Paper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color='info' onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='info'
          disabled={selectedVariables.length === 0}
          onClick={() => onApply(selectedVariables)}
        >Apply</Button>
      </DialogActions>
    </Dialog>
  );
}
