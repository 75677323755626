import React, { useState } from "react";

import Typography from '@mui/material/Typography';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

import { useTheme } from "@mui/material/styles";

import { ExploreFact, ExploreFactPlus } from "@/interfaces";
import Button from "@mui/material/Button";
import { formatExploreFact } from "@/utils/common";

type FactRow = {
  // category: string;
  type: string
  base: number | string;
  value: number | string;
  index: number;
};

function createRow(fact: ExploreFactPlus, category: string | null): FactRow {
  return {
    // category: `${fact.type.replace(/^[0-9]+-? /m, '').split(' - ')[0]}`,
    type: `${fact.type.replace(/^[0-9]+-? /m, '')}`,
    base: formatExploreFact(fact.base, category ? category : fact.source),
    value: formatExploreFact(fact.value_avg, category ? category : fact.source),
    index: Number((Number(fact.value_avg) / Number(fact.base)).toFixed(2))
  };
}

type Fact = {
  [key: string]: Array<ExploreFact>;
}

type FactValueCardProps = {
  title: string;
  scores: Fact;
  category: string | null;
};

export const ExploreFactValueCard: React.FC<FactValueCardProps> = (
  {
    title,
    scores,
    category
  }
) => {

  const theme = useTheme();
  const [expand, setExpand] = useState(false);

  const keys = Object.keys(scores);
  const data = Object.values(scores).map((x, idx) => x.map((y) => ({ ...y, source: keys[idx]}))).flat()
  let source = '';

  const onExpand = () => {
    setExpand(!expand);
  };

  return (
    <Card elevation={4} sx={{ mb: 3 }}>
      <CardHeader title={title} titleTypographyProps={{ variant: 'body1' }} sx={{ pb: 0 }}/>
      {expand &&
        <CardContent sx={{ pb: 0 }}>
          <Table size='small' padding='none'>
            <TableHead>
              <TableRow>
                {/*<TableCell sx={{fontWeight: 400, fontSize: '12px', pb: 1, width: '20%', color: theme.palette.text.secondary}}>Category</TableCell>*/}
                <TableCell sx={{
                  fontWeight: 400,
                  fontSize: '12px',
                  pb: 1,
                  width: '40%',
                  color: theme.palette.text.secondary
                }}>Name</TableCell>
                <TableCell align='center' sx={{
                  fontWeight: 400,
                  fontSize: '12px',
                  pb: 1,
                  width: '10%',
                  color: theme.palette.text.secondary
                }}>UK Base</TableCell>
                <TableCell align='center' sx={{
                  fontWeight: 400,
                  fontSize: '12px',
                  pb: 1,
                  width: '10%',
                  color: theme.palette.text.secondary
                }}>Value</TableCell>
                <TableCell align='center' sx={{
                  fontWeight: 400,
                  fontSize: '12px',
                  pb: 1,
                  width: '10%',
                  color: theme.palette.text.secondary
                }}>Index</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((variable, variableIdx) => {
                const row = createRow(variable, category);
                const newSource = variable.source;
                if (newSource !== source) {
                  source = newSource;
                  return (
                    <React.Fragment key={`fact-row-${variableIdx}`}>
                      <TableRow>
                        <TableCell align='left' sx={{
                          fontSize: '13px',
                          // fontFamily: 'cera-pro-bold',
                          pt: 3,
                          pb: 1,
                          color: theme.palette.primary.main
                        }}>{newSource}</TableCell>
                        <TableCell align='center' sx={{ fontSize: '12px' }}></TableCell>
                        <TableCell align='center' sx={{ fontSize: '12px' }}></TableCell>
                        <TableCell align='center' sx={{ fontSize: '12px' }}></TableCell>
                      </TableRow>
                      <TableRow>
                        {/*<TableCell align='left' sx={{fontSize: '12px', py: 1}}>{row.category}</TableCell>*/}
                        <TableCell align='left' sx={{ fontSize: '12px', py: 0.5 }}>{row.type}</TableCell>
                        <TableCell align='center'
                                   sx={{ fontSize: '12px', color: theme.palette.text.secondary, py: 0.5 }}>{row.base}</TableCell>
                        <TableCell align='center' sx={{ fontSize: '12px', py: 0.5 }}>{row.value}</TableCell>
                        <TableCell align='center' sx={{
                          fontSize: '12px',
                          py: 0.5,
                          color: row.index > 1 ? theme.palette.success.light : theme.palette.error.main
                        }}>{row.index}</TableCell>
                      </TableRow>
                    </React.Fragment>
                  )
                } else {
                  return (
                    <TableRow key={`fact-row-${variableIdx}`}>
                      {/*<TableCell align='left' sx={{fontSize: '12px', py: 1}}>{row.category}</TableCell>*/}
                      <TableCell align='left' sx={{ fontSize: '12px', py: 0.5 }}>{row.type}</TableCell>
                      <TableCell align='center'
                                 sx={{ fontSize: '12px', color: theme.palette.text.secondary }}>{row.base}</TableCell>
                      <TableCell align='center' sx={{ fontSize: '12px' }}>{row.value}</TableCell>
                      <TableCell align='center' sx={{
                        fontSize: '12px',
                        color: row.index > 1 ? theme.palette.success.light : theme.palette.error.main
                      }}>{row.index}</TableCell>
                    </TableRow>
                  )
                }
              })}
            </TableBody>
          </Table>
        </CardContent>
      }
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button size='small' color='primary' onClick={onExpand}>
          {expand ? 'Hide Scores' : 'Show Scores'}
        </Button>
      </CardActions>
    </Card>

  );
}
