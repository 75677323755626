import React from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper";
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Dialog  from '@mui/material/Dialog';
import DialogContent from "@mui/material/DialogContent";

import identify_image from "@/assets/images/identify_image_small_grey.png";
import compare_image from "@/assets/images/compare_image_small_grey.png";
import explore_image from "@/assets/images/explore_image_small_grey.png";

const options = ['Identify', 'Catchment', 'Explore'];

let titles: {[option: string]: Array<string>} = {};
titles.identify = ['Identify'];
titles.catchment = ['Catchment'];
titles.explore = ['Explore'];

let descriptions : {[option: string]: string} = {};
descriptions.identify = 'Find the best development opportunities across the country.';
descriptions.catchment = 'Build catchments and profile against bespoke baselines for custom insights.';
descriptions.explore = 'Explore granular location data.';

let colors : {[option: string]: string} = {};
colors.identify = '#f5b49f';
colors.catchment = '#ab9ec9';
colors.explore = '#82e1f2';

let images : {[option: string]: string} = {};
images.identify = identify_image;
images.catchment = compare_image;
images.explore = explore_image;

export const LandingOverlay = () => {

  const open = true;

  return (

    <Dialog
      aria-labelledby="landing-dialog"
      open={open}
      fullWidth
      maxWidth='lg'
      PaperProps={{
        elevation: 2,
        sx: {
          position: "fixed",
          top: 150,
          height: '50%',
          minWidth: '1024px',
          borderRadius: '10px'
        }
      }}
    >
      <DialogContent>
        <Typography variant={'h4'}>
          Welcome to the Locational Toolkit
        </Typography>
      </DialogContent>
      <DialogContent>
        <Grid container spacing={2}>
          {options.map((option, optionIdx) => (
            <Grid item xs={4} key={option}>
              <Stack>
                <Link to={`/${option.toLowerCase()}`} style={{ textDecoration: 'none' }}>
                  <Paper
                    elevation={3}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 250,
                      borderRadius: '20px',
                      backgroundImage: `url(${images[option.toLowerCase()]})`,
                      boxShadow: `inset 0 0 500px rgba(11, 22, 39, 0.7)`,
                      // minWidth: '340px',
                      p: 2,
                      '&:hover': {
                        // boxShadow: 20,
                        boxShadow: `
                        inset 0 0 500px rgba(11, 22, 39, 0.7),
                        inset 0 0 50px 10px ${colors[option.toLowerCase()]}
                        `,
                      }
                    }}
                  >
                    {titles[option.toLowerCase()].map((text, textIdx) => (
                      <Typography
                        key={`title-${optionIdx}-${textIdx}`}
                        sx={{ textTransform: 'uppercase', fontSize: '40px', fontFamily: 'cera-pro-bold', lineHeight: 1.2 }}
                      >
                        {text}
                      </Typography>
                    ))}
                  </Paper>
                </Link>
                <Box p={2}>
                  <Typography variant='h6'>
                    {descriptions[option.toLowerCase()]}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
